import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  background-image: url(${(p) => p.image});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  margin-bottom: 40px;
  @media (max-width: 900px) {
    height: 230px;
    margin-bottom: 40px;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  background-color: var(--verde);
  color: var(--blanco);
  display: inline-flex;
  font-size: 32px;

  @media (max-width: 900px) {
    font-size: 19px;
  }
`;
const Hand = styled.div`
  font-size: 80px;
  font-family: "Just Another Hand";
  color: var(--blanco);
  display: inline-flex;
  @media (max-width: 900px) {
    font-size: 46px;
  }
`;

const Hero = (props) => {
  return (
    <Container image={props.imagen}>
      <Title>{props.title}</Title>
      <Hand>{props.hand}</Hand>
    </Container>
  );
};

export default Hero;
